import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'properties',
    loadChildren: () => import('./properties/properties.module').then( m => m.PropertiesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'properties/:search',
    loadChildren: () => import('./properties/properties.module').then( m => m.PropertiesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'property/:folio',
    canActivate: [AuthGuard],
    loadChildren: () => import('./property/property.module').then( m => m.PropertyPageModule)
  },
  //{
  //  path: 'property/:id',
  //  loadChildren: () => import('./property/property.module').then( m => m.PropertyPageModule),
  //  canActivate: [AuthGuard]
  //},
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
